<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
    data: () => {
        return {
            title: "Cadastro da escola",
            items: [
                {
                    text: "Escola",
                    href: "/",
                },
                {
                    text: "Dados",
                    active: true,
                },
            ],
            contratos: [
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    validate_at: '01/01/2022',
                    status: 'ativo',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'pendente',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'vencido',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'cancelado',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
            ]
        }
    },
    components: {Layout, PageHeader},
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h1>{{ title }}</h1>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<style scoped lang="scss">

</style>
